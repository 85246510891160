// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-confirm-tsx": () => import("./../src/pages/confirm.tsx" /* webpackChunkName: "component---src-pages-confirm-tsx" */),
  "component---src-pages-guest-found-tsx": () => import("./../src/pages/guest-found.tsx" /* webpackChunkName: "component---src-pages-guest-found-tsx" */),
  "component---src-pages-guest-not-found-tsx": () => import("./../src/pages/guest-not-found.tsx" /* webpackChunkName: "component---src-pages-guest-not-found-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-rsvp-tsx": () => import("./../src/pages/rsvp.tsx" /* webpackChunkName: "component---src-pages-rsvp-tsx" */)
}

