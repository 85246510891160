/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import netlifyIdentity from "netlify-identity-widget"

require("typeface-inter")
require("focus-visible")

export function onInitialClientRender(a, options) {
  netlifyIdentity.init()
  window.netlifyIdentity = netlifyIdentity
}
